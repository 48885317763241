<app-send-payment
  *ngIf="environmentType"
  [returnUrl]="returnUrl"
  [clientId]="clientId"
  [paymentOptions]="paymentOptions"
  [metadata]="metadata"
  (submitSuccess)="handleSuccess($event)"
  (submitFailure)="handleFailure($event)"
  (declineConsent)="handleDecline()"
>
</app-send-payment>
<div class="sbx-overlay" *ngIf="sendPaymentComponentRef?.paymentSubmitInProgress"></div>
