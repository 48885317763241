import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'sbx-context-subproc-button',
})
export class SbxContextSubprocButtonComponent extends UpgradeComponent {
  @Input() key: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('sbContextSubprocButton', elementRef, injector);
  }
}
