import { Inject, Injectable } from '@angular/core';
import { ButtonView, Command, Plugin } from 'ckeditor5';
import { BehaviorSubject } from 'rxjs';
import { ISbxModalConfig } from '../../../sbx-modal/interfaces';
import { SbxModalService } from '../../../sbx-modal/sbx-modal.service';
import { SbxExhibitModalComponent } from './sbx-exhibit-modal/sbx-exhibit-modal.component';
import icon from './theme/icons/bttn-exhibit.svg';
const UNKNOWN_ID = 'unknown';

type ExhibitPillsConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exhibits: any[];
};

function getClassInstance(services) {
  class AddOrEditExhibitCommand extends Command {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listing: Array<any> = [];
    // eslint-disable-next-line @typescript-eslint/ban-types
    target: Object;
    // eslint-disable-next-line @typescript-eslint/ban-types
    range: Object = null;

    constructor(editor) {
      super(editor);
      this.range = editor.model.document.selection.getFirstRange();
    }

    execute(options) {
      this.target = options.target;
      this.populateForm(this.listing, this.target);
    }

    populateForm(listing, target) {
      if (target) {
        target._setAttribute('selected', 'true');
      }
      listing.forEach((item) => {
        item.target = target;
      });
      const config: ISbxModalConfig<unknown> = {
        size: 'md',
        backdropClass: 'custom-modal-backdrop',
        windowClass: 'custom-modal',
        data: {
          listing: this.listing,
        },
      };
      const newRange = this.editor.model.document.selection.getFirstRange();
      if (newRange.end.path[1]) {
        this.range = newRange;
      }
      return services.sbxModalService
        .open(SbxExhibitModalComponent, config)
        .result.then(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (res: any) => this.assignExhibit(res.result, this.range),
          // eslint-disable-next-line no-empty-function, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
          (error: any) => {},
        );
    }

    assignExhibit(resPromise, range) {
      resPromise.then((res) => {
        const editor = this.editor;
        const id = res.id;
        const title = res.title;

        editor.model.change((writer) => {
          const exhibitPillElem = writer.createElement('exhibitPills', {
            'data-exhibit-id': id === undefined ? UNKNOWN_ID : id,
            'data-is-assigned': true,
            innerHTML: 'Exhibit 📎',
            tooltipContent: title,
          });
          writer.setSelection(range);
          editor.model.insertContent(exhibitPillElem);
        });
      });
    }
  }

  return class AddOrEditExhibit extends Plugin {
    init() {
      const editor = this.editor;
      const cmd = new AddOrEditExhibitCommand(this.editor);
      editor.commands.add('exhibitPills', cmd);

      // Ckeditor config's "get" method returns the deep copy of the config.
      // Only original Behavior Subject emits values.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const exhibitPills = editor.config._config.data.exhibitPills as
        | undefined
        | BehaviorSubject<ExhibitPillsConfig>;

      if (exhibitPills) {
        exhibitPills.subscribe((data) => {
          cmd.listing = data.exhibits;
        });
      }
      editor.ui.componentFactory.add('addOrEditExhibit', (locale) => {
        const view = new ButtonView(locale);
        view.set({
          icon: icon,
        });
        view.iconView.template.attributes.class = ['ck-button-exhibit'];
        view.on('execute', () => {
          editor.execute('exhibitPills', { target: null });
        });
        return view;
      });
    }
  };
}

@Injectable()
export class SbxExhibitPillService {
  // Negative lookbehind assertion to check if there is no word character (`\w`)
  // followed by an equal sign (`=`) and an optional attribute value before the target
  // text. This excludes cases where "Exhibit X" is inside an attribute.
  EXHIBIT_REGEX = /(?<!\w=["'][^"']*?)Exhibit [A-Z]/g;

  constructor(@Inject(SbxModalService) public sbxModalService: SbxModalService) {}

  getExhibitClass() {
    return getClassInstance({
      sbxModalService: this.sbxModalService,
    });
  }

  formatPill(data) {
    if (!this.EXHIBIT_REGEX.test(data)) {
      return data;
    }

    const formattedPill = data.replace(this.EXHIBIT_REGEX, () => {
      return `<span class="richtext-exhibit-pill" data-is-assigned="false">Exhibit 📎</span>`;
    });
    return formattedPill;
  }
}
