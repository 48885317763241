import { Routes } from '@angular/router';

export enum AuthRoutePath {
  login = 'login',
  legacy = 'legacy',
  transitionStatus = 'transition-status',
  choice = 'choice',
  signupComplete = 'signup-complete',
  merge = 'merge',
  passwordRecovery = 'password-recovery',
  verificationCode = 'verification-code',
  newUser = 'new-user',
  agreement = 'agreement',
}

export const authRoutes: Routes = [
  {
    path: '',
    data: { hideBackground: true },
    children: [
      {
        path: AuthRoutePath.login,
        loadComponent: () =>
          import(
            './sbx-auth-login-page/sbx-auth-login-page.component'
          ).then((c) => c.SbxAuthLoginPageComponent),
      },
      {
        path: AuthRoutePath.legacy,
        loadComponent: () =>
          import(
            './sbx-auth-legacy-login-page/sbx-auth-legacy-login-page.component'
          ).then((c) => c.SbxAuthLegacyLoginPageComponent),
      },
      {
        path: AuthRoutePath.transitionStatus,
        loadComponent: () =>
          import(
            './sbx-auth-transition-status/sbx-auth-transition-status.component'
          ).then((c) => c.SbxAuthTransitionStatusComponent),
      },
      {
        path: AuthRoutePath.choice,
        loadComponent: () =>
          import(
            './sbx-auth-choice-page/sbx-auth-choice-page.component'
          ).then((c) => c.SbxAuthChoicePageComponent),
      },
      {
        path: AuthRoutePath.signupComplete,
        loadComponent: () =>
          import(
            './sbx-auth-signup-complete-page/sbx-auth-signup-complete-page.component'
          ).then((c) => c.SbxAuthSignupCompletePageComponent),
      },
      {
        path: AuthRoutePath.merge,
        loadComponent: () =>
          import(
            './sbx-auth-merge-page/sbx-auth-merge-page.component'
          ).then((c) => c.SbxAuthMergePageComponent),
      },
      {
        path: AuthRoutePath.passwordRecovery,
        loadComponent: () =>
          import(
            './sbx-auth-password-recovery-page/sbx-auth-password-recovery-page.component'
          ).then((c) => c.SbxAuthPasswordRecoveryPageComponent),
      },
      {
        path: AuthRoutePath.verificationCode,
        loadComponent: () =>
          import(
            './sbx-auth-verification-code/sbx-auth-verification-code.component'
          ).then((c) => c.SbxAuthVerificationCodeComponent),
      },
      {
        path: AuthRoutePath.newUser,
        loadComponent: () =>
          import(
            './sbx-auth-new-user-page/sbx-auth-new-user-page.component'
          ).then((c) => c.SbxAuthNewUserPageComponent),
      },
      {
        path: AuthRoutePath.agreement,
        loadComponent: () =>
          import(
            './sbx-auth-agreement-page/sbx-auth-agreement-page.component'
          ).then((c) => c.SbxAuthAgreementPageComponent),
      },
    ],
  },
];
