import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
/**
 * @ngdoc service
 * @name sb.lib.document.service:EntityDocumentsService
 *
 * @description
 *
 * Service for communication with entity documents backend.
 */
export const EntityDocumentsService = [
  '$observable',
  'BackendLocation',
  'SbxAsyncTaskService',
  function ($observable, BackendLocation, SbxAsyncTaskService) {
    return {
      /**
       * @ngdoc method
       *
       * @name $getDocument
       * @methodOf sb.lib.document.service:EntityDocumentsService
       *
       * @param {string} id Document id
       * @param {string} entId Entity Id of the document
       * @param {boolean} [ensureUptodate=false] Render the document if its out of date.
       *
       * @returns {observable<Document>} A stream of the document object.
       */
      $getDocument: function (id, entId, ensureUptodate) {
        return new Observable((observer) => {
          const baseUrl = BackendLocation.root('1') + entId;
          return $observable
            .sbAjax({
              url: `${baseUrl}/documents/${id}${
                ensureUptodate ? '?ensureUptodate=1' : ''
              }`,
            })
            .subscribe(
              (ajaxValue) => {
                if (ajaxValue.status === 200) {
                  observer.next(ajaxValue.response);
                }
              },
              (error) => {
                observer.error(error);
              },
              () => {
                observer.complete();
              },
            );
        });
      },

      /**
       * @ngdoc method
       *
       * @name $waitForDocumentToRender
       * @methodOf sb.lib.document.service:EntityDocumentsService
       * @description
       *
       * Return {observable<bool>} that completes when all document tasks
       * complete and returns true when all tasks succeeded.
       *
       * @param {object} doc Document to wait for. Document can be retrieved
       *     with `$getDocument`.
       */
      $waitForDocumentToRender: function (doc) {
        if (!doc.rendering) {
          return of(!doc.failedToRender);
        }
        return SbxAsyncTaskService.resultOfAllIds(doc.renderResultIds).pipe(
          first((result) => result.state !== 'PENDING'),
          map((result) => result.state === 'DONE'),
        );
      },

      /**
       * @ngdoc method
       *
       * @name $waitForDocumentToCertify
       * @methodOf sb.lib.document.service:EntityDocumentsService
       * @description
       *
       * Return {observable<bool>} that completes when all document tasks
       * complete and returns true when all tasks succeeded.
       *
       * @param {object} doc Document to wait for. Document can be retrieved
       *     with `$getDocument`.
       */
      $waitForDocumentToCertify: function (doc) {
        if (!doc.certifying) {
          return of(!doc.failedToCertify);
        }
        return SbxAsyncTaskService.resultOfAllIds(doc.certifyResultIds).pipe(
          first((result) => result.state !== 'PENDING'),
          map((result) => result.state === 'DONE'),
        );
      },
    };
  },
]; // end EntityDocumentsService
