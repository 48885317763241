import { ISbxIconType } from '@/core/constants/icons';
import { SbxHttpClient } from '@/core/http';
import { Downgrade } from '@/shared/downgrade';
import { checkForSuspiciousTypes } from '@/shared/utils/file.util';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { of } from 'rxjs';
import { ISbxActionsMenu } from '../sbx-action-menu/sbx-action-menu.component';
import { SbxConfirmationModalService } from '../sbx-confirmation-modal/sbx-confirmation-modal.service';
import { SbxFormModalService } from '../sbx-form-modal/sbx-form-modal.service';
import { SbxAsyncTaskService } from '../sbx-task/sbx-async-task.service';

interface IPopoverData {
  icon: ISbxIconType;
  class: string;
  popoverText: string;
}

export enum FileStatus {
  failed = 'failed',
  unsupported = 'unsupported',
  uploading = 'uploading',
  uploaded = 'uploaded',
}

export interface IFile {
  id?: string;
  title: string;
  url?: string;
  status: FileStatus;
  file?: File;
}

const SELECTOR = 'sbx-dropzone';

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-dropzone.component.html',
  styleUrls: ['./sbx-dropzone.component.scss'],
})
export class SbxDropzoneComponent implements OnInit {
  files: IFile[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() model: any;
  @Input() readOnly = false;
  @Input() canEdit = false;
  @Input() canDelete = false;
  @Input() uploadUrl: string;
  @Input() key: string;
  @Input() formField = 'files';
  @Input() apiVersion = '2';
  @Input() checkUploadStatus = false;
  @Input() multiple = true;
  @Output() handleDropzoneChange = new EventEmitter();

  constructor(
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    @Inject(SbxFormModalService) public sbxFormModalService: SbxFormModalService,
    @Inject(SbxConfirmationModalService)
    public sbxConfirmationModalService: SbxConfirmationModalService,
    @Inject(SbxAsyncTaskService) public sbxAsyncTaskService: SbxAsyncTaskService,
  ) {}

  ngOnInit() {
    if (Array.isArray(this.model[this.key])) {
      this.files = this.model[this.key].map(
        ({ id, title, url }: { id: string; title: string; url: string }): IFile => ({
          id,
          title,
          url,
          status: FileStatus.uploaded,
        }),
      );
    }
  }

  @HostListener('dragover', ['$event']) handleDragover(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) handleDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.readOnly) {
      this.handleFileChange(event.dataTransfer.files);
    }
  }

  handleFileChange(files: FileList | File[]) {
    if (!this.multiple) {
      if (this.files.length > 0) {
        return;
      }

      files = [files[0]];
    }

    const mappedFiles = Array.from(files).map((file: File) => {
      const newFile: IFile = {
        title: file.name,
        status: FileStatus.uploading,
        file,
      };
      const fd = new FormData();
      fd.append(`${this.formField}`, newFile.file);

      if (checkForSuspiciousTypes(newFile.file)) {
        newFile.status = FileStatus.unsupported;
        return newFile;
      }
      // XXX we should pass full url from backend instead of hardcoding version here
      this.sbxHttpClient
        .entity(this.apiVersion)
        .post(this.uploadUrl, {
          params: fd,
        })
        .subscribe(
          ({ upload_id: uploadId, task_id: taskId }) => {
            newFile.id = uploadId;
            if (this.checkUploadStatus) {
              // Do polling for V1, used in dataroom
              this.updateUploadStatus(uploadId, taskId);
            } else {
              newFile.status = FileStatus.uploaded;
            }
            this.setFieldValue();
          },
          (error: HttpErrorResponse) => {
            newFile.status =
              error.status === 415 ? FileStatus.unsupported : FileStatus.failed;
            this.setFieldValue();
          },
        );

      return newFile;
    });

    this.files.push(...mappedFiles);
    this.setFieldValue();
  }

  async updateUploadStatus(uploadId: string, taskId: string) {
    const fileUploadStatus = await this.getUploadStatus(taskId);
    const file = this.files.find((f) => f.id === uploadId);

    if (file) {
      file.status = FileStatus[fileUploadStatus];
    }
  }

  getUploadStatus(taskId: string): Promise<FileStatus> {
    return this.sbxAsyncTaskService
      .resultOfId(taskId)
      .then(() => FileStatus.uploaded)
      .catch(() => FileStatus.failed);
  }

  setFieldValue() {
    // XXX: We should refactor it once we migrate to formly v5.
    // general practice would be to setValue once file is uploaded, but can only access model in field validation
    // so we need to track uploading files and mark model invalid until all files are uploaded
    const files = this.files
      .filter((f) => f.status !== FileStatus.failed)
      .map(({ id, title, status }) => ({
        id,
        title,
        status,
      }));
    this.handleDropzoneChange.emit(files);
  }

  getDataFromStatus(type: FileStatus): IPopoverData {
    switch (type) {
      case FileStatus.uploading:
        return {
          icon: 'spinner',
          class: 'sbx-icon-uploading',
          popoverText: 'File is uploading',
        };

      case FileStatus.uploaded:
        return {
          icon: 'checkCircle',
          class: 'sbx-icon-uploaded',
          popoverText: 'File has been uploaded',
        };

      case FileStatus.failed:
        return {
          icon: 'exclamationCircle',
          class: 'sbx-icon-failed',
          popoverText: 'An error occurred while uploading the file',
        };
      case FileStatus.unsupported:
        return {
          icon: 'exclamationCircle',
          class: 'sbx-icon-failed',
          popoverText:
            'The selected file can not be uploaded. This file type is not permitted for security reasons.',
        };
    }
  }

  getFileActions(fileId: string): ISbxActionsMenu {
    return [
      {
        title: 'Edit',
        icon: 'edit',
        click: () => {
          const file = this.files.find((f) => f.id === fileId);

          this.sbxFormModalService
            .open({
              data: {
                title: 'Edit Document Title',
                formFields: of([
                  {
                    key: 'title',
                    type: 'string-textline',
                    defaultValue: file.title,
                    templateOptions: {
                      label: 'Title',
                      required: true,
                    },
                  },
                ]),
              },
            })
            .result.then(({ result }) => {
              file.title = result.title;
              this.setFieldValue();
            })
            .catch(() => null);
        },
        collapsed: false,
        hidden: !this.canEdit,
      },
      {
        title: 'Delete',
        icon: 'delete',
        click: async () => {
          await this.sbxConfirmationModalService
            .open({
              data: {
                title: 'Do you really want to delete this file?',
              },
            })
            .result.then(() => {
              this.files = this.files.filter((file) => file.id !== fileId);
              this.setFieldValue();
            })
            .catch(() => null);
        },
        collapsed: false,
        hidden: !this.canDelete,
      },
    ];
  }
}
