import { Component, Input } from '@angular/core';

export interface SubprocessInfo {
  title: string;
  key: string;
  contentType: string;
  fmtDesc?: string;
  desc?: string;
}
@Component({
  selector: 'sbx-subprocess-info',
  templateUrl: './sbx-subprocess-info.component.html',
})
export class SbxSubprocessInfoComponent {
  @Input()
  public model: SubprocessInfo[];

  public trackByTitle(index: number, info: SubprocessInfo): string {
    return info.title;
  }
}
