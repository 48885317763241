import { InjectionToken } from '@angular/core';

export type ISbxSerializeAndSubmitProcessForm = (
  buttonName: string,
  extraData?: {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  },
  removeButtonPrefix?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<any>;

export const SbxSerializeAndSubmitProcessForm =
  new InjectionToken<ISbxSerializeAndSubmitProcessForm>(
    'SbxSerializeAndSubmitProcessForm',
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SerializeAndSubmitProcessFormServiceFactory(i: any) {
  return i.get('SerializeAndSubmitProcessForm');
}

export const SerializeAndSubmitProcessFormServiceProvider = {
  provide: SbxSerializeAndSubmitProcessForm,
  useFactory: SerializeAndSubmitProcessFormServiceFactory,
  deps: ['$injector'],
};
