import { Injectable } from '@angular/core';
import { ButtonView, Plugin } from 'ckeditor5';
import { BehaviorSubject } from 'rxjs';
import resolvedIcon from './theme/icons/bttn-resolved.svg';
import whereasIcon from './theme/icons/bttn-whereas.svg';

type InsertClauseConfig = {
  title: string;
};

function getClassInstance() {
  return class InsertClause extends Plugin {
    init() {
      const editor = this.editor;
      let title = '';

      // Ckeditor config's "get" method returns the deep copy of the config.
      // Only original Behavior Subject emits values.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const insertClause = editor.config._config.data
        .insertClause as BehaviorSubject<InsertClauseConfig>;

      insertClause.subscribe((data) => {
        title = data.title;
      });

      editor.ui.componentFactory.add('insertClause', (locale) => {
        const view = new ButtonView(locale);

        const icon = title === 'WHEREAS' ? whereasIcon : resolvedIcon;

        view.set({
          icon: icon,
        });
        view.iconView.template.attributes.class = ['ck-button-clause'];

        view.on('execute', () => {
          const content = '<strong>' + title + '</strong>:';
          const viewFragment = editor.data.processor.toView(content);
          const modelFragment = editor.data.toModel(viewFragment);

          editor.model.change((writer) => {
            const position = editor.model.document.selection.getFirstPosition();
            editor.model.insertContent(modelFragment, position);
            const range = position.getLastMatchingPosition(() => true);
            const newRange = writer.createRange(range, range);
            writer.setSelection(newRange);
          });
        });

        return view;
      });
    }
  };
}

@Injectable()
export class SbxInsertClauseService {
  CLAUSE_REGEX = RegExp('WHEREAS:|RESOLVED:', 'g');

  getInsertClauseClass() {
    return getClassInstance();
  }

  formatClause(data) {
    if (!this.CLAUSE_REGEX.test(data)) {
      return data;
    }

    let formattedPill = data.replace(this.CLAUSE_REGEX, (match) => {
      return `<strong>${match.slice(0, -1)}</strong>:`;
    });
    formattedPill = formattedPill
      .replace('<strong><strong>', '<strong>')
      .replace('</strong>:</strong>', '</strong>:');
    return formattedPill;
  }
}
