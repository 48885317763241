import { Downgrade } from '@/shared/downgrade';
import { Component, Input } from '@angular/core';
import { ProcessInfo } from '../sbx-process-info/sbx-process-info.component';
import { ProcessInfoDocument } from '../sbx-process-info-documents/sbx-process-info-documents.component';
import { Stakeholder } from '@shoobx/types/webapi-v2';
import { SubprocessInfo } from '../sbx-subprocess-info/sbx-subprocess-info.component';

interface Sidebar {
  processInfo: ProcessInfo[];
  subprocs: SubprocessInfo[];
  docs: ProcessInfoDocument[];
  stakeholders: Stakeholder[];
}

const SELECTOR = 'sbx-process-sidebar';

@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-process-sidebar.component.html',
  styleUrl: './sbx-process-sidebar.component.scss',
})
export class SbxProcessSidebarComponent {
  @Input()
  public model: Sidebar;
}
