import { ISbxIconType } from '@/core/constants/icons';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sbx-process-sidebar-block',
  templateUrl: './sbx-process-sidebar-block.component.html',
  styleUrl: './sbx-process-sidebar-block.component.scss',
})
export class SbxProcessSidebarBlockComponent {
  @Input()
  public isOpen: boolean = true;
  @Input()
  public headerIcon: ISbxIconType;
}
