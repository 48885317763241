import { addToolbarToDropdown, ButtonView, createDropdown, Plugin } from 'ckeditor5';
import icon from './theme/icons/style-char.svg';
import { SPAN_STYLES } from './utils';
const CUSTOMSTYLE = 'customSpanStyle';

export default class CustomSpanStyleUI extends Plugin {
  /**
   * @inheritDoc
   */
  init() {
    const editor = this.editor;
    const componentFactory = editor.ui.componentFactory;
    const options = editor.config.get('customSpanStyle.options');

    SPAN_STYLES.forEach((option) => this._addButton(option));

    componentFactory.add('customSpanStyle', (locale) => {
      const dropdownView = createDropdown(locale);

      const buttons = options.map((option) =>
        componentFactory.create(`customSpanStyle:${option.type}`),
      );
      addToolbarToDropdown(dropdownView, buttons, { isVertical: true });

      // Configure dropdown properties an behavior.
      dropdownView.buttonView.set({
        icon: icon,
        tooltip: false,
      });
      // Enable button if any of the buttons is enabled.
      dropdownView
        .bind('isEnabled')
        .toMany(buttons, 'isEnabled', (...areEnabled) =>
          areEnabled.some((isEnabled) => isEnabled),
        );

      this.listenTo(dropdownView, 'execute', () => {
        this.editor.editing.view.focus();
      });

      return dropdownView;
    });
  }

  _addButton(option) {
    const editor = this.editor;

    editor.ui.componentFactory.add(`customSpanStyle:${option.type}`, (locale) => {
      const command = editor.commands.get(CUSTOMSTYLE);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: option.label,
        withText: true,
        tooltip: false,
      });

      // Bind button model to command.
      buttonView.bind('isEnabled').to(command);
      buttonView.bind('isOn').to(command, 'value', (value) => value === option.type);

      // Execute command.
      this.listenTo(buttonView, 'execute', () => {
        editor.execute(CUSTOMSTYLE, { value: option.type });
        editor.editing.view.focus();
      });

      return buttonView;
    });
  }
}
