import { InjectionToken } from '@angular/core';
import { ISbxReviewAndEditDocumentsModel } from './review-and-edit-documents-model.service';

export type ISbxEditTermsModal = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  doc: Map<string, any>,
  persist: ISbxReviewAndEditDocumentsModel,
  baseUrl: string,
  openSection: unknown,
  refreshOnSave: boolean,
) => Promise<unknown>;

export const SbxEditTermsModal = new InjectionToken<ISbxEditTermsModal>(
  'SbxEditTermsModal',
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function EditTermsModalServiceFactory(i: any) {
  return i.get('$editTermsModal');
}

export const EditTermsModalServiceProvider = {
  provide: SbxEditTermsModal,
  useFactory: EditTermsModalServiceFactory,
  deps: ['$injector'],
};
