import { SbxHttpClient } from '@/core/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GenericCreateForm,
  GenericStatusResponse,
  Legend1933,
  LegendGroups,
  PreviewLegends,
} from '@shoobx/types/webapi-v2';

@Injectable({ providedIn: 'root' })
export class SbxStockCertificateLegendApiService {
  public constructor(private readonly http: SbxHttpClient) {}

  public getLegend1933$(): Observable<Legend1933> {
    return this.http.entity('2').get('legends/legend1933');
  }

  public getLegendGroups$(): Observable<LegendGroups> {
    return this.http.entity('2').get('legends/groups');
  }

  public getLegendForm$(): Observable<GenericCreateForm> {
    return this.http.entity('2').get('legends/form');
  }

  public deleteLegend$(params: {
    id: string;
    metadataPath: string;
    hash: string;
    index: number;
  }): Observable<GenericStatusResponse> {
    const { id, metadataPath, hash, index } = params;

    return this.http
      .entity('2')
      .delete(`legends/${id}/${metadataPath}/${hash}/${index}`);
  }

  public resetLegend1933$(): Observable<GenericStatusResponse> {
    return this.http.entity('2').delete('legends/legend1933');
  }

  public exportGroup$(params: {
    id: string;
    metadataPath: string;
  }): Observable<PreviewLegends> {
    const { id, metadataPath } = params;

    return this.http.entity('2').get(`legends/${id}/${metadataPath}/preview`);
  }
}
