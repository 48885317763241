import angular from 'angular';
import URL from '../lib/url';
import Promise from 'lib/promise';
import Messages from './../lib/messages/messages';
import Modal from '../lib/modal/modal';
import BSTabs from 'bs-tabs-wrap';

import { $workitemRecord } from './model';
import {
  ProcessButtonModel,
  sbProcessButtons,
  sbProcessSubmitLink,
  sbDisableProcessButton,
  sbProcessStatusButton,
  sbWorkitemRemindButton,
  sbWorkitemClaimButton,
} from './button';
import {
  sbOpenProcessModal,
  $processModal,
  ProcessModalCtrl,
  $processStatusModal,
  ProcessModalUrlService,
} from './modal';
import {
  ProcessStatus,
  sbProcessStatusWidget,
  ProcessStatusModel,
  sbWorkitemStatusList,
  sbWorkitemStatusTooltip,
} from './status';
import { sbWizard, sbWizardGroup } from './wizard';
import { ProcessModel, ProcessCtrl, sbAnchorClick } from './state';
import { SerializeAndSubmitProcessForm, sbWiHtml, sbProcess } from './html';
import { sbContextSubprocButton } from './contextsubproc';

/**
 * @ngdoc overview
 * @name sb.process
 *
 * @description
 * This module houses the Shoobx process components.
 */
export default angular
  .module('sb.process', [Modal, BSTabs, URL, Messages, Promise])
  .component('sbWorkitemStatusTooltip', sbWorkitemStatusTooltip)
  .component('sbWorkitemStatusList', sbWorkitemStatusList)
  .component('sbProcessStatusButton', sbProcessStatusButton)
  .component('sbWorkitemRemindButton', sbWorkitemRemindButton)
  .component('sbWorkitemClaimButton', sbWorkitemClaimButton)
  .component('sbProcessStatusWidget', sbProcessStatusWidget)
  .component('sbContextSubprocButton', sbContextSubprocButton)
  .controller('ProcessModalCtrl', ProcessModalCtrl)
  .controller('ProcessCtrl', ProcessCtrl)
  .directive('sbProcessSubmitLink', sbProcessSubmitLink)
  .directive('sbProcessButtons', sbProcessButtons)
  .directive('sbDisableProcessButton', sbDisableProcessButton)
  .directive('sbOpenProcessModal', sbOpenProcessModal)
  .directive('sbWizard', sbWizard)
  .directive('sbWizardGroup', sbWizardGroup)
  .directive('sbProcess', sbProcess)
  .directive('sbWiHtml', sbWiHtml)
  .directive('sbAnchorClick', sbAnchorClick)
  .factory('ProcessModel', ProcessModel)
  .factory('$workitemRecord', $workitemRecord)
  .factory('ProcessButtonModel', ProcessButtonModel)
  .factory('$processModal', $processModal)
  .factory('$processStatusModal', $processStatusModal)
  .factory('ProcessStatus', ProcessStatus)
  .factory('ProcessStatusModel', ProcessStatusModel)
  .factory('SerializeAndSubmitProcessForm', SerializeAndSubmitProcessForm)
  .service('ProcessModalUrlService', ProcessModalUrlService).name;
