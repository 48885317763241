import {
  ISbxProcessModel,
  SbxProcessModel,
} from '@/shared/upgraded/process-model.service';
import {
  ISbxPromiseErrorCatcher,
  SbxPromiseErrorCatcher,
} from '@/shared/upgraded/promise-error-catcher.service';
import {
  ISbxStakeholderInviteModal,
  SbxStakeholderInviteModal,
} from '@/shared/upgraded/stakeholder-invite-modal.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Stakeholder } from '@shoobx/types/webapi-v2';

@Component({
  selector: 'sbx-process-info-stakeholders',
  templateUrl: './sbx-process-info-stakeholders.component.html',
  styleUrl: './sbx-process-info-stakeholders.component.scss',
})
export class SbxProcessInfoStakeholdersComponent implements OnInit {
  @Input()
  public model: Stakeholder[];

  public canShowStakeholderInvite: Map<string, boolean> = new Map<string, boolean>();

  public constructor(
    @Inject(SbxProcessModel) private readonly processModel: ISbxProcessModel,
    @Inject(SbxStakeholderInviteModal)
    private readonly stakeholderInviteModal: ISbxStakeholderInviteModal,
    @Inject(SbxPromiseErrorCatcher)
    private readonly promiseErrorCatcher: ISbxPromiseErrorCatcher,
  ) {}

  public ngOnInit(): void {
    this.processModel
      .$canShowStakeholdersInvite(
        this.model.map((stakeholder: Stakeholder) => stakeholder.id),
      )
      .then((response) => response.data.stakeholders)
      .then((stakeholderIds: string[]) => {
        stakeholderIds.forEach((stakeholderId: string) => {
          const stakeholder: Stakeholder = this.model.find(
            (stakeholder: Stakeholder) => stakeholder.id === stakeholderId,
          );
          if (stakeholder) {
            this.canShowStakeholderInvite.set(
              stakeholderId,
              stakeholder.canBeUpdated && stakeholder.invited,
            );
          }
        });
      })
      .catch(this.promiseErrorCatcher);
  }

  public inviteStakeholder(stakeholder: Stakeholder): void {
    const wiId = this.processModel.$wi.id;
    this.stakeholderInviteModal(stakeholder, wiId)
      .then(({ $wasNew, $stakeholder }) => {
        if ($wasNew) {
          this.processModel.$setStakeholder($stakeholder);
        }
      })
      .catch(this.promiseErrorCatcher);
  }

  public trackById(index: number, stakeholder: Stakeholder): string {
    return stakeholder.id;
  }
}
