import { ProcessInfoDocument } from '@/sbx-process/sbx-process-info-documents/sbx-process-info-documents.component';
import { InjectionToken } from '@angular/core';

export type ISbxReviewAndEditDocumentsModelInit = (
  url: string,
) => ISbxReviewAndEditDocumentsModel;

export interface ISbxReviewAndEditDocumentsModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $documents: Map<string, any>[];
  $loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $saveDocs(docData: { documents: ProcessInfoDocument[] }): Map<string, any>[];
  $initDocuments(): Promise<unknown[]>;
  $loadDocumentFully(docId: string): Promise<unknown>;
  $saveTerms(
    docId: string,
    termData: unknown,
    customSections: unknown,
    formattedTitle: string,
  ): Promise<unknown>;
  $saveAcceptanceInfo(description: string): Promise<unknown>;
  $saveRejectionInfo(): Promise<unknown>;
  $saveThirdPartyReviewInfo(documents: unknown[]): Promise<unknown>;
  $rejectSuggestions(): Promise<unknown>;
  $revertChanges(): Promise<unknown>;
  $shareDocuments(emailData: unknown, documents: string[]): Promise<unknown>;
  $revokeAccess(stakeholderId: string, title: string): Promise<unknown>;
}

export const SbxReviewAndEditDocumentsModel =
  new InjectionToken<ISbxReviewAndEditDocumentsModelInit>('SbxProcessModel');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ReviewAndEditDocumentsModelServiceFactory(i: any) {
  return i.get('ReviewAndEditDocumentsModel');
}

export const ReviewAndEditDocumentsModelServiceProvider = {
  provide: SbxReviewAndEditDocumentsModel,
  useFactory: ReviewAndEditDocumentsModelServiceFactory,
  deps: ['$injector'],
};
