import { ModuleWithProviders, NgModule } from '@angular/core';
import { SbxSendPaymentComponent } from '@/shared/sbx-send-payment/sbx-send-payment.component';
import { SendPaymentModule } from '@fmr-ap163331/sps-money-movement-integration-ui';
import { CommonModule } from '@angular/common';
import { SbxSendPaymentService } from '@/shared/sbx-send-payment/sbx-send-payment.service';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';

@NgModule({
  imports: [SendPaymentModule, CommonModule, SbxButtonModule],
  declarations: [SbxSendPaymentComponent],
  exports: [SbxSendPaymentComponent],
  providers: [SbxSendPaymentService],
})
export class SbxSendPaymentModule {
  static entryComponents = [SbxSendPaymentComponent];

  static forRoot(): ModuleWithProviders<SbxSendPaymentModule> {
    return {
      ngModule: SbxSendPaymentModule,
    };
  }
}
