import angular from 'angular';
import Rx from 'lib/rx';

import { sbAsyncTaskSubscriber } from './tasks';

/**
 * @ngdoc overview
 * @name sb.lib.tasks
 *
 * @description
 * This module houses handy task utilities.
 */
export default angular
  .module('sb.lib.tasks', [Rx])

  .directive('sbAsyncTaskSubscriber', sbAsyncTaskSubscriber).name;
