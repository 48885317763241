import { Plugin, toWidget, toWidgetEditable } from 'ckeditor5';
import { CustomElemCommand } from './CustomElementCommand';

export default class CustomElemUI extends Plugin {
  init() {
    const editor = this.editor;
    const items = editor.config.get('CustomElement.items');

    for (let i = 0; i < items.length; i++) {
      const tag = items[i].tag;
      const text = this._safeGet(items[i].placeholder, tag);
      const attr = this._safeGet(items[i].attributes, {});
      const inline = this._safeGet(items[i].inline, false);
      const editable = this._safeGet(items[i].editable, false);
      const schema = this._safeGet(items[i].schema, false);

      const attrkeys = Object.keys(attr);

      editor.model.schema.register(
        tag,
        schema || {
          ...(inline
            ? {
                allowWhere: '$text',
              }
            : {
                allowIn: '$root',
              }),
          allowAttributes: attrkeys,
          isObject: true,
          isBlock: true,
        },
      );

      editor.model.schema.extend('$text', {
        allowIn: tag,
      });

      editor.conversion.for('editingDowncast').elementToElement(
        editable
          ? {
              model: tag,
              view: (modelItem, conversionApi) => {
                const widgetElement = conversionApi.writer.createContainerElement(tag);
                return toWidgetEditable(widgetElement, conversionApi.writer);
              },
            }
          : {
              model: tag,
              view: (modelItem, conversionApi) => {
                const widgetElement = conversionApi.writer.createContainerElement(tag);
                return toWidget(widgetElement, conversionApi.writer);
              },
            },
      );
      editor.conversion.for('dataDowncast').elementToElement({
        model: tag,
        view: tag,
      });
      editor.conversion.for('upcast').elementToElement({
        view: tag,
        model: tag,
      });
      for (let a = 0; a < attrkeys.length; a++) {
        editor.conversion.for('downcast').attributeToAttribute({
          model: attrkeys[a],
          view: attrkeys[a],
          converterPriority: 'low',
        });
        editor.conversion.for('upcast').attributeToAttribute({
          view: attrkeys[a],
          model: attrkeys[a],
          converterPriority: 'low',
        });
      }
      const com = 'custom-element-' + tag;
      editor.commands.add(com, new CustomElemCommand(editor, tag, text, inline, attr));
    }
  }

  _safeGet(input, safeDefault) {
    if (typeof input !== 'undefined' && (input || input === false || input === 0)) {
      return input;
    }
    return safeDefault;
  }
}
