<sbx-process-sidebar-block headerIcon="infoCircle">
  <ul class="sbx-info-list">
    <li *ngFor="let info of model; trackBy: trackByTitle">
      <span class="sbx-group-title">{{ info.title }}</span>
      <sbx-compiled-content
        *ngIf="info.contentType === 'html'"
        [content]="{ res: info.fmtDesc || info.desc }"
      />
      <span *ngIf="info.contentType === 'text'">{{ info.fmtDesc || info.desc }}</span>
    </li>
  </ul>
</sbx-process-sidebar-block>
