import angular from 'angular';
import { EMPTY, Observable } from 'rxjs';
import { map, switchAll, startWith } from 'rxjs/operators';

/**
 * @ngdoc directive
 * @name sb.lib.tasks.directive:sbAsyncTaskSubscriber
 * @restrict A
 *
 * @description
 * This directive is for easy/HTML use of `AsyncTasks`. It adds convience task status data and launch
 * task functions to the scope of the user. NOTE: This might not be flexiable enough for all use cases
 * so one should not shy away from using `AsyncTasks` directly.
 *
 * @element ANY
 * @param {expression} sbAsyncTaskSubscriber This is a two way data binding where this directive
 *   will assign the status of the task. This is a `TaskResult` from `AsyncTasks`.
 * @param {expression} [sbAsyncTaskSubscriberLaunchFn=undefined] If provided, the directive
 *   will assign a function that takes a (full) endpoint string and optional json data object.
 *   as params.
 * @param {array<Object>} [sbAsyncTaskSubscriberInitialTasks=undefined] If provided, this will
 *  subscribe to the result of all the task objects in this list.
 *    @property {string} id Standard task ID.
 *
 * @example
   <button type="button" class="btn btn-int btn-xs"
     sb-async-task-subscriber="vm.taskStatus"
     sb-async-task-subscriber-launch-fn="vm.launchTask"
     ng-click="vm.launchTask('/ent/doWork', { someData: true })"
     ng-disabled="vm.taskStatus.state === 'PENDING'">
     <i class="fa fa-edit"></i>
     <ng-switch on="vm.taskStatus.state">
       <span ng-switch-when="PENDING">Loading...</span>
       <span ng-switch-when="DONE">All done, with result: {{ ::vm.taskStatus.result }}</span>
       <span ng-switch-when="FAILED">Something went wrong: {{ ::vm.taskStatus.result }}</span>
       <span ng-switch-default>Launch the task, baby!</span>
     </ng-switch>
   </button>
 */
export const sbAsyncTaskSubscriber = [
  '$parse',
  '$exceptionHandler',
  'SbxAsyncTaskService',
  function ($parse, $exceptionHandler, SbxAsyncTaskService) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const parsedStatus = $parse(attrs.sbAsyncTaskSubscriber);
        const setStatus = (nv) => parsedStatus.assign(scope, nv);
        const parsedLaunchFn =
          attrs.sbAsyncTaskSubscriberLaunchFn &&
          $parse(attrs.sbAsyncTaskSubscriberLaunchFn);
        const setLaunchFn = parsedLaunchFn
          ? (nv) => parsedLaunchFn.assign(scope, nv)
          : angular.noop;

        const initTasks = $parse(attrs.sbAsyncTaskSubscriberInitialTasks)(scope);
        const initTaskStatus$ =
          initTasks && initTasks.length
            ? SbxAsyncTaskService.resultOfAllIds(initTasks.map((task) => task.id))
            : EMPTY;

        new Observable((observer) => {
          setLaunchFn((url, data) => observer.next({ url, data }));
        })
          .pipe(
            map((request) =>
              SbxAsyncTaskService.startTaskEndpoint(request.url, request.data),
            ),
            startWith(initTaskStatus$),
            switchAll(),
          )
          .$applySubscribe(scope, setStatus, $exceptionHandler);
      },
    };
  },
]; // end sbAsyncTaskSubscriber
