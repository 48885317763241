import { Command } from 'ckeditor5';

const CUSTOMSTYLE = 'customSpanStyle';

export default class CustomSpanStyleCommand extends Command {
  refresh() {
    const model = this.editor.model;
    const doc = model.document;
    this.value = doc.selection.getAttribute(CUSTOMSTYLE);
    this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, CUSTOMSTYLE);
  }

  execute(options = {}) {
    const model = this.editor.model;
    const doc = model.document;
    const selection = doc.selection;
    const value = options.forceValue === undefined ? !this.value : options.forceValue;

    model.change((writer) => {
      if (selection.isCollapsed) {
        if (value) {
          writer.setSelectionAttribute(CUSTOMSTYLE, options.value);
        } else {
          writer.removeSelectionAttribute(CUSTOMSTYLE);
        }
      } else {
        const ranges = model.schema.getValidRanges(selection.getRanges(), CUSTOMSTYLE);

        for (const range of ranges) {
          if (value) {
            writer.setAttribute(CUSTOMSTYLE, options.value, range);
          } else {
            writer.removeAttribute(CUSTOMSTYLE, range);
          }
        }
      }
    });
  }
}
