import { Plugin } from 'ckeditor5';
import CustomSpanStyleCommand from './customSpanStyleCommand';
import { SPAN_STYLES } from './utils';
const CUSTOMSTYLE = 'customSpanStyle';

export default class CustomSpanStyleEditing extends Plugin {
  constructor(editor) {
    super(editor);
    editor.config.define(CUSTOMSTYLE, {
      options: SPAN_STYLES,
    });
  }

  init() {
    const editor = this.editor;
    const schema = editor.model.schema;
    const enabledOptions = editor.config.get('customSpanStyle.options');

    schema.extend('$text', { allowAttributes: CUSTOMSTYLE });
    const definition = _buildDefinition(enabledOptions);

    editor.conversion.attributeToElement(definition);
    editor.commands.add(CUSTOMSTYLE, new CustomSpanStyleCommand(editor));
  }
}

// @private
function _buildDefinition(options) {
  const definition = {
    model: {
      key: 'customSpanStyle',
      values: options.map((option) => option.type).slice(),
    },
    view: {},
  };
  options.forEach((option) => {
    definition.view[option.type] = {
      name: 'span',
      classes: option.class,
    };
  });
  return definition;
}
