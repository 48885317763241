import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@/shared/shared.module';
import { SbxProcessSidebarComponent } from './sbx-process-sidebar/sbx-process-sidebar.component';
import { SbxProcessInfoComponent } from './sbx-process-info/sbx-process-info.component';
import { SbxSubprocessInfoComponent } from './sbx-subprocess-info/sbx-subprocess-info.component';
import { SbxProcessInfoDocumentsComponent } from './sbx-process-info-documents/sbx-process-info-documents.component';
import { SbxProcessInfoStakeholdersComponent } from './sbx-process-info-stakeholders/sbx-process-info-stakeholders.component';
import { SbxProcessSidebarBlockComponent } from './sbx-process-sidebar-block/sbx-process-sidebar-block.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    SbxProcessSidebarComponent,
    SbxProcessInfoComponent,
    SbxSubprocessInfoComponent,
    SbxProcessInfoDocumentsComponent,
    SbxProcessInfoStakeholdersComponent,
    SbxProcessSidebarBlockComponent,
  ],
})
export class SbxProcessModule {
  static entryComponents = [
    SbxProcessSidebarComponent,
    SbxProcessInfoComponent,
    SbxSubprocessInfoComponent,
    SbxProcessInfoDocumentsComponent,
    SbxProcessInfoStakeholdersComponent,
    SbxProcessSidebarBlockComponent,
  ];

  static forRoot(): ModuleWithProviders<SbxProcessModule> {
    return {
      ngModule: SbxProcessModule,
    };
  }
}
