import { InjectionToken } from '@angular/core';
import { Stakeholder } from '@shoobx/types/webapi-v2';

export interface ISbxProcessModel {
  $wi: { id: string };
  $load: () => (wiId: string) => Promise<unknown>;
  $setStakeholder: (newSh: Stakeholder, oldSh?: Stakeholder) => Stakeholder;
  $resolveWorkItemId: (activityId: string) => Promise<string>;
  $goto: (stepName: string) => Promise<unknown>;
  $submit: (formData: unknown) => Promise<unknown>;
  $newestWorkitemId: () => Promise<unknown>;
  $canShowStakeholderInvite: (stakeholder: Stakeholder) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $canShowStakeholdersInvite: (stakeholderIDs: string[]) => Promise<any>;
}

export const SbxProcessModel = new InjectionToken<ISbxProcessModel>('SbxProcessModel');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ProcessModelServiceFactory(i: any) {
  return i.get('ProcessModel');
}

export const ProcessModelServiceProvider = {
  provide: SbxProcessModel,
  useFactory: ProcessModelServiceFactory,
  deps: ['$injector'],
};
