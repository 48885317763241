import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DndModule } from 'ngx-drag-drop';
import { SharedModule } from '@/shared/shared.module';
import { SbxInvestmentService } from './sbx-investment.service';
import { SbxExitScenarioPlannerService } from './sbx-exit-scenario-planner/sbx-exit-scenario-planner.service';
import { SbxFundService } from './sbx-fund/sbx-fund.service';
import { SbxLiquidationStackComponent } from './sbx-liquidation-stack/sbx-liquidation-stack.component';
import { SbxLiquidationStackModalComponent } from './sbx-liquidation-stack-modal/sbx-liquidation-stack-modal.component';
import { SbxLiquidationStackModalService } from './sbx-liquidation-stack-modal/sbx-liquidation-stack-modal.service';
import { SbxInvestorModalService } from './sbx-investor-modal/sbx-investor-modal.service';
import { SbxInvestorModalComponent } from './sbx-investor-modal/sbx-investor-modal.component';
import { SbxNextRoundPlannerComponent } from './sbx-next-round-planner/sbx-next-round-planner.component';
import { SbxNextRoundPlannerService } from './sbx-next-round-planner/sbx-next-round-planner.service';
import { SbxProcessNextRoundPlannerComponent } from './sbx-process-next-round-planner/sbx-process-next-round-planner.component';
import { SbxSectionTitleComponent } from './sbx-section-title/sbx-section-title.component';

// SbxInvestmentModule is split into two modules - one is lazy and loaded via
// router, other one is included into main bundle and used in non spa pages
// like workflows

@NgModule({
  imports: [CommonModule, SharedModule, DndModule],
  declarations: [
    SbxLiquidationStackComponent,
    SbxLiquidationStackModalComponent,
    SbxInvestorModalComponent,
    SbxNextRoundPlannerComponent,
    SbxProcessNextRoundPlannerComponent,
    SbxSectionTitleComponent,
  ],
  providers: [
    SbxInvestmentService,
    SbxFundService,
    SbxExitScenarioPlannerService,
    SbxLiquidationStackModalService,
    SbxInvestorModalService,
    SbxNextRoundPlannerService,
    SbxFundService,
  ],
  exports: [
    SbxLiquidationStackComponent,
    SbxLiquidationStackModalComponent,
    SbxInvestorModalComponent,
    SbxNextRoundPlannerComponent,
    SbxProcessNextRoundPlannerComponent,
    SbxSectionTitleComponent,
  ],
})
export class SbxInvestmentModule {
  static entryComponents = [
    SbxProcessNextRoundPlannerComponent,
    SbxLiquidationStackComponent,
  ];

  static forRoot(): ModuleWithProviders<SbxInvestmentModule> {
    return {
      ngModule: SbxInvestmentModule,
    };
  }
}
