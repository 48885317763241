import { InjectionToken } from '@angular/core';

export type ISbxPromiseErrorCatcher = (error: unknown) => void;

export const SbxPromiseErrorCatcher = new InjectionToken<ISbxPromiseErrorCatcher>(
  'SbxPromiseErrorCatcher',
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PromiseErrorCatcherServiceFactory(i: any) {
  return i.get('PromiseErrorCatcher');
}

export const PromiseErrorCatcherServiceProvider = {
  provide: SbxPromiseErrorCatcher,
  useFactory: PromiseErrorCatcherServiceFactory,
  deps: ['$injector'],
};
