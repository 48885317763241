<sbx-process-sidebar-block headerIcon="user" [isOpen]="model?.length <= 20">
  <ul class="sbx-info-list">
    <li *ngFor="let stakeholder of model; trackBy: trackById" [id]="stakeholder.id">
      <span>{{ stakeholder.fullName }}</span>
      <a
        class="sbx-no-select"
        *ngIf="canShowStakeholderInvite.get(stakeholder.id)"
        (click)="inviteStakeholder(stakeholder)"
      >
        <sbx-icon type="envelope" />
      </a>
      <sbx-edit-stakeholder
        *ngIf="stakeholder.ephemeral && !stakeholder.invited"
        buttonType="icon"
        [stakeholderId]="stakeholder.id"
        [canInvite]="false"
        [stakeholderInvited]="stakeholder.invited"
        [reload]="true"
      />
    </li>
  </ul>
</sbx-process-sidebar-block>
