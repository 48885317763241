import { BackendLocation } from '@/core/http';
import {
  ISbxEditTermsModal,
  SbxEditTermsModal,
} from '@/shared/upgraded/edit-terms-modal.service';
import {
  ISbxProcessStatus,
  SbxProcessStatus,
} from '@/shared/upgraded/process-status.service';
import {
  ISbxProcessUrlInfo,
  SbxProcessUrlInfo,
} from '@/shared/upgraded/process-url-info.service';
import {
  ISbxPromiseErrorCatcher,
  SbxPromiseErrorCatcher,
} from '@/shared/upgraded/promise-error-catcher.service';
import {
  ISbxReviewAndEditDocumentsModel,
  ISbxReviewAndEditDocumentsModelInit,
  SbxReviewAndEditDocumentsModel,
} from '@/shared/upgraded/review-and-edit-documents-model.service';
import {
  ISbxSelectEditResolutionModal,
  SbxSelectEditResolutionModal,
} from '@/shared/upgraded/select-edit-resolution-modal.service';
import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';

const BOARD_MINUTES_TEMPLATE_NAME = 'board-minutes';

export interface ProcessInfoDocument {
  id: string;
  entityId: string;
  title: string;
  status: string;
  shouldDisplayStatus: boolean;
  isSigned: boolean;
  isCertified: boolean;
  isFinalized: boolean;
  isNonPdf: boolean;
  process_id: string;
  obsolete: boolean;
  allowed: boolean;
  states: {
    type: string;
    title: string;
  }[];
  docrefs: string[];
  tags: string[];
  expandedTags: string[];
  types: string[];
  prominent_metadata: {
    label: string;
    value_html: string;
  }[];
  upload_available: boolean;
  edit_available: boolean;
  renderResultIds: string[];
  certifyResultIds: string[];
  rendering: boolean;
  certifying: boolean;
  failedToRender: boolean;
  failedToCertify: boolean;
  deferred: boolean;
  canShare: boolean;
  deletable: boolean;
  canMove: boolean;
  icon: string;
  tooltip: string;
  canDownload: boolean;
  canDownloadRevisionHistory: boolean;
  canDownloadWatermarked: boolean;
  canDownloadCustomization: boolean;
  canExport: boolean;
  location: string;
  href: {
    ui: string;
    pdf: string;
    review_content: string;
    preview_content: string;
    content: string;
  };
  templateBaseName: string;
  templateRevision: string;
  templateTitle: string;
  templateBranding: unknown;
  $viewable: boolean;
  $editable: boolean;
  $editableAsResolution: boolean;
  $editableDocTitle: boolean;
  $suggestions: unknown;
  $lastTemplateAcceptance: unknown;
  $recentChangesCount: number;
  $lastRevisionNumber: number;
  $templateEdits: unknown;
}

@Component({
  selector: 'sbx-process-info-documents',
  templateUrl: './sbx-process-info-documents.component.html',
  styleUrl: './sbx-process-info-documents.component.scss',
})
export class SbxProcessInfoDocumentsComponent implements OnChanges {
  @Input()
  public model: ProcessInfoDocument[];

  public readonly apiUrl: string;
  public readonly documentsModel: ISbxReviewAndEditDocumentsModel;

  public constructor(
    @Inject(SbxProcessUrlInfo) processUrlInfo: ISbxProcessUrlInfo,
    @Inject(SbxReviewAndEditDocumentsModel)
    private readonly reviewAndEditDocumentsModel: ISbxReviewAndEditDocumentsModelInit,
    @Inject(SbxProcessStatus) private readonly processStatus: ISbxProcessStatus,
    @Inject(SbxSelectEditResolutionModal)
    private readonly selectEditResolutionModal: ISbxSelectEditResolutionModal,
    @Inject(SbxEditTermsModal) private readonly editTermsModal: ISbxEditTermsModal,
    @Inject(SbxPromiseErrorCatcher)
    private readonly promiseErrorCatcher: ISbxPromiseErrorCatcher,
    backendLocation: BackendLocation,
  ) {
    this.apiUrl = `${backendLocation.entity(
      '1',
    )}processes/${processUrlInfo.processId()}/review_and_edit/`;
    this.documentsModel = this.reviewAndEditDocumentsModel(this.apiUrl);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.documentsModel.$saveDocs({ documents: changes.model.currentValue || [] });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public openDocumentEditModal(document: Map<string, any>): void {
    this.documentsModel
      .$loadDocumentFully(document.get('id'))
      .catch((error) => {
        this.processStatus.$setStatus(error, 'danger');
        return Promise.reject(error);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((loadedDoc: Map<string, any>) => {
        if (loadedDoc.get('$editableAsResolution')) {
          this.selectEditResolutionModal({
            doc: loadedDoc,
            isWorkitem: false,
            hasVoting:
              loadedDoc.get('templateBaseName') === BOARD_MINUTES_TEMPLATE_NAME,
          });
        } else {
          this.editTermsModal(loadedDoc, this.documentsModel, this.apiUrl, null, true);
        }
      })
      .catch(this.promiseErrorCatcher);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackById(index: number, document: Map<string, any>): string {
    return document.get('id');
  }
}
