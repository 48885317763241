import { ISbxFormModalConfig } from '@/shared/sbx-form-modal/sbx-form-modal-config.type';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ButtonView, Command, Plugin } from 'ckeditor5';
import { BehaviorSubject } from 'rxjs';
import { SbxFormModalService } from '../../../sbx-form-modal/sbx-form-modal.service';
import icon from './theme/icons/bttn-term.svg';

type TermPillsConfig = {
  form: {
    fields: object;
    fieldsFormats: object;
  };
  values: object;
};

function getClassInstance(services) {
  class AddOrEditTermCommand extends Command {
    fields: object;
    fieldsFormats: object;
    target: object;
    annotations: object;

    constructor(editor) {
      super(editor);
    }

    execute(options) {
      this.target = options.target;
      this.populateForm(this.fields, this.fieldsFormats, this.target);
    }

    populateForm(fields, fieldsFormats, target) {
      const form = new FormGroup({});
      const field =
        target === null ? fields[0].value : target.getAttribute('data-field');
      const format =
        target === null || target.getAttribute('data-formatter') === 'undefined'
          ? 'default'
          : target.getAttribute('data-formatter');
      const model = {
        field: field,
        format: format,
      };

      if (field in fieldsFormats) {
        const formatter$ = new BehaviorSubject(
          this.prepareFields(field, fields, fieldsFormats),
        );
        const config: ISbxFormModalConfig = {
          backdropClass: 'custom-modal-backdrop',
          windowClass: 'custom-modal',
          data: {
            title: 'Select Term',
            form: form,
            model: model,
            formFields: formatter$,
            okButtonTitle: target ? 'Update' : 'Insert',
            handleModelChange: (data) => {
              formatter$.next(
                this.prepareFields(data.model.field, fields, fieldsFormats),
              );
            },
          },
        };
        const range = this.editor.model.document.selection.getFirstRange();
        services.sbxFormModalService
          .open(config)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .result.then((res: any) => this.addTermFn(res, range));
      }
    }

    prepareFields(field, fields, fieldsFormats) {
      return [
        {
          key: 'field',
          data: {},
          type: 'enum-dropdown',
          templateOptions: {
            required: true,
            label: 'Term',
            enumVocab: fields,
          },
        },
        {
          type: 'enum-dropdown',
          templateOptions: {
            subfield: 1,
            required: true,
            label: 'Format',
            enumVocab: fieldsFormats[field],
          },
          data: {},
          key: 'format',
          defaultValue: '',
          hideExpression: fieldsFormats[field].length < 2,
        },
      ];
    }

    addTermFn(res, range) {
      const editor = this.editor;
      const field = res.result.field;
      const term = this.annotations[field];
      let format = '';
      if (term.formats.length === 1) {
        format = 'default';
      } else {
        format = res.result.format;
      }
      const tooltipTitle = term.title;
      const termFormat = term.formats.find((f) => f.format === format);
      const innerHTML = termFormat.value || 'No value yet';
      const tooltipContent = termFormat.value;

      editor.model.change((writer) => {
        const termPillElem = writer.createElement('termPills', {
          'data-field': field,
          'data-formatter': format,
          innerHTML: innerHTML,
          tooltipTitle: tooltipTitle,
          tooltipContent: tooltipContent,
        });
        writer.setSelection(range);
        editor.model.insertContent(termPillElem);
      });
    }
  }

  return class AddOrEditTerm extends Plugin {
    init() {
      const editor = this.editor;
      const cmd = new AddOrEditTermCommand(this.editor);
      editor.commands.add('termPills', cmd);

      // Ckeditor config's "get" method returns the deep copy of the config.
      // Only original Behavior Subject emits values.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const termPills = editor.config._config.data.termPills as
        | undefined
        | BehaviorSubject<TermPillsConfig>;

      if (termPills) {
        termPills.subscribe((data) => {
          cmd.fields = data.form.fields;
          cmd.fieldsFormats = data.form.fieldsFormats;
          cmd.annotations = data.values;
        });
      }

      editor.ui.componentFactory.add('addOrEditTerm', (locale) => {
        const view = new ButtonView(locale);
        view.set({
          icon: icon,
        });
        view.iconView.template.attributes.class = ['ck-button-term'];
        view.on('execute', () => {
          editor.execute('termPills', { target: null });
        });
        return view;
      });
    }
  };
}

@Injectable()
export class SbxTermPillService {
  constructor(
    @Inject(SbxFormModalService) public sbxFormModalService: SbxFormModalService,
  ) {}

  getTermClass() {
    return getClassInstance({ sbxFormModalService: this.sbxFormModalService });
  }
}
