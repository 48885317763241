import { Component, Input } from '@angular/core';

export interface ProcessInfo {
  title: string;
  type: string;
  contentType: string;
  fmtDesc?: string;
  desc?: string;
}

@Component({
  selector: 'sbx-process-info',
  templateUrl: './sbx-process-info.component.html',
})
export class SbxProcessInfoComponent {
  @Input()
  public model: ProcessInfo[];

  public trackByTitle(index: number, info: ProcessInfo): string {
    return info.title;
  }
}
