import { Plugin } from 'ckeditor5';
import CustomElemUI from './CustomElementUI';

export default class CustomElement extends Plugin {
  static get requires() {
    return [CustomElemUI];
  }
  static get pluginName() {
    return 'CustomElement';
  }
}
