import { InjectionToken } from '@angular/core';
import { Stakeholder } from '@shoobx/types/webapi-v2';

export type ISbxStakeholderInviteModal = (
  stakeholder: Stakeholder,
  workitemId: string,
) => Promise<{
  $wasNew: boolean;
  $email: string;
  $stakeholder: Stakeholder;
  $sent: boolean;
  $statusMsg: string;
}>;

export const SbxStakeholderInviteModal = new InjectionToken<ISbxStakeholderInviteModal>(
  'SbxStakeholderInviteModal',
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function StakeholderInviteModalServiceFactory(i: any) {
  return i.get('$stakeholderInviteModal');
}

export const StakeholderInviteModalServiceProvider = {
  provide: SbxStakeholderInviteModal,
  useFactory: StakeholderInviteModalServiceFactory,
  deps: ['$injector'],
};
