import { InjectionToken } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ISbxSelectEditResolutionModal = (config: any) => Promise<unknown>;

export const SbxSelectEditResolutionModal =
  new InjectionToken<ISbxSelectEditResolutionModal>('SbxSelectEditResolutionModal');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SelectEditResolutionModalServiceFactory(i: any) {
  return i.get('$selectEditResolutionModal');
}

export const SelectEditResolutionModalServiceProvider = {
  provide: SbxSelectEditResolutionModal,
  useFactory: SelectEditResolutionModalServiceFactory,
  deps: ['$injector'],
};
